import { BuilderConfig } from '../../types/Builder';
import { IconType } from '../Icons';
import IconList, { Props } from './icon-list';

export const TwoColImageBuilderConfig = {
  name: 'Icon List',
  inputs: [
    { name: 'title', type: 'string' },
    {
      name: 'description',
      type: 'string',
    },
    {
      name: 'contents',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'content', type: 'string', require: true },
        { name: 'itemUrl', type: 'string' },
      ],
    },
    {
      name: 'type',
      type: 'string',
      defaultValue: 'large',
      enum: ['large', 'small'],
    },

    {
      name: 'icon',
      type: 'string',
      enum: Object.values(IconType),
      defaultValue: IconType.StreamlineIconInterfaceLock,
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: IconList,
  config: TwoColImageBuilderConfig,
};

export default builderConfig;
