/* eslint-disable @next/next/no-img-element */
import classNames from 'classnames';
import Image from 'next/image';
import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
  SwiperOptions,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from '../../../hook/useWindowSize';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import { CarouselItemType } from '../carousel';
import styles from './flow-carousel.module.scss';
import { onSetTranslate } from './FlowEffect';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
SwiperCore.use([Pagination, Navigation, EffectCoverflow]);

export type PhoneColorType = 'Midnight' | 'Green' | 'Purple' | 'Yellow';
export type FlowCarouselColor =
  | 'moss-20'
  | 'moss-40'
  | 'fire-20'
  | 'fire-40'
  | 'orca-20'
  | 'orca-45';

export type FlowCarouselProps = {
  mode?: 'center' | 'right';
  color?: FlowCarouselColor;
  phoneColor?: PhoneColorType;
  items: CarouselItemType[];
  loop?: boolean;
  onItemChange?: (item: CarouselItemType) => void;
};

export type FlowCarouselHandle = {
  slideTo: (index: number, speed?: number, runCallbacks?: boolean) => void;
  slideToLoop: (index: number, speed?: number, runCallbacks?: boolean) => void;
};

const InternalCarousel: ForwardRefRenderFunction<
  FlowCarouselHandle,
  FlowCarouselProps
> = (
  { mode = 'center', color, items, phoneColor, loop = true, onItemChange },
  ref
) => {
  const { width = 0 } = useWindowSize();
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  let initialSlide = 0;
  if (!loop) {
    if (items.length <= 3) {
      initialSlide = items.length / 2;
    } else if (items.length <= 5) {
      initialSlide = 1;
    }
  }
  const options: SwiperOptions = {
    grabCursor: true,
    keyboard: true,
    effect: 'coverflow',
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: true,
    speed: 400,
    loop,
    initialSlide,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    coverflowEffect: {
      rotate: 0,
      // Trick for custom effect (using define center spacing)
      stretch: 33,
      modifier: 0,
      slideShadows: false,
    },
    breakpoints: {
      320: {
        spaceBetween: 30,
        effect: 'slide',
      },
      575: {
        slidesOffsetAfter: 140,
        spaceBetween: 20,
        coverflowEffect: {
          stretch: 71,
          depth: 330,
          modifier: 1,
        },
      },
      768: {
        spaceBetween: 50,
        coverflowEffect: {
          stretch: 90,
          depth: 300,
          modifier: 1,
        },
      },
    },
  };

  useImperativeHandle(ref, () => ({
    slideTo: (index, speed, runCallbacks) =>
      swiper?.slideTo(index, speed, runCallbacks),
    slideToLoop: (index, speed, runCallbacks) =>
      swiper?.slideToLoop(index, speed, runCallbacks),
  }));
  const iphoneShadow = '../../../images/iphone/shadow.png';
  let iphoneType = '../../../images/iphone/green.png';
  switch (phoneColor) {
    case 'Midnight':
      iphoneType = '../../../images/iphone/midnight.png';
      break;
    case 'Purple':
      iphoneType = '../../../images/iphone/purple.png';
      break;
    case 'Yellow':
      iphoneType = '../../../images/iphone/yellow.png';
      break;
    default:
      break;
  }

  return (
    <div
      className={classNames(styles.container, styles[`container--${color}`])}
    >
      <div className={styles[mode]}>
        <div className={styles.Content}>
          <Swiper
            {...options}
            onSetTranslate={onSetTranslate}
            onSwiper={setSwiper}
            onSlideChange={(swiper) => {
              if (onItemChange) {
                const currentSlideIdx = swiper.realIndex;
                onItemChange(items[currentSlideIdx]);
              }
            }}
          >
            {items?.map((item, index) => {
              const imgWidth = width > 767 || width < 575 ? 313.04 : 199.11;
              const imgHeight = width > 767 || width < 575 ? 666.31 : 429.42;
              return (
                <SwiperSlide key={index}>
                  <div className={color}>
                    <Image
                      alt={item.name}
                      src={item.image}
                      width={imgWidth}
                      height={imgHeight}
                      layout="fixed"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={classNames(styles.Iphone, styles.IphoneMock)}>
            <img data-src={iphoneType} alt="" className="lazyload" />
          </div>
          <div className={classNames(styles.Iphone, styles.IphoneShadow)}>
            <img data-src={iphoneShadow} alt="" className="lazyload"/>
          </div>

          <Button
            ref={navigationPrevRef}
            className={classNames(styles.Navigate, styles.NavigatePrev)}
            type="olive"
            icon={<Icons type={IconType.Left} />}
            size="large"
          />
          <Button
            ref={navigationNextRef}
            className={classNames(styles.Navigate, styles.NavigateNext)}
            type="olive"
            icon={<Icons type={IconType.Right} />}
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

const FlowCarousel = React.forwardRef<FlowCarouselHandle, FlowCarouselProps>(
  InternalCarousel
);
FlowCarousel.displayName = 'FlowCarousel';

export default FlowCarousel;
