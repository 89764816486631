import React, { ReactNode } from 'react';

interface Props {
  email?: string;
  subject: string;
  body: string;
  children: ReactNode;
}

const MailTo: React.FC<Props> = (props: Props) => {
  const { email='', subject = '', body = '', children } = props;
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

export default MailTo;
