export const BACKGROUND_COLORS = {
  TRANSPARENT: 'transparent',
  MINT: 'mint',
  MOSS_10:'moss-10',
  MOSS_20: 'moss-20',
  MOSS_40: 'moss-40',
  FIRE_20: 'fire-20',
  FIRE_40: 'fire-40',
  ORCA_20: 'orca-20',
  ORCA_40: 'orca-40',
  ORCA_45: 'orca-45',
} as const;

export type BACKGROUND_COLOR =
  typeof BACKGROUND_COLORS[keyof typeof BACKGROUND_COLORS];

export const PHONE_COLORS = {
  MIDNIGHT: 'Midnight',
  GREEN: 'Green',
  PURPLE: 'Purple',
  YELLOW: 'Yellow',
} as const;

export type PHONE_COLOR = typeof PHONE_COLORS[keyof typeof PHONE_COLORS];
