import classNames from 'classnames';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import React, { useState } from 'react';
import { useColor } from '../../hook/useColor';
import VimeoEmbed from '../vimeo-embed/vimeo-embed';
import { BACKGROUND_COLOR, BACKGROUND_COLORS } from './../../shared/colors';
import styles from './image-hero.module.scss';

export type ImageHeroProps = {
  src: string | '';
  ratio?: number[];
  className?: string;
  colorless?: boolean;
  cornerRadius?: number;
  imageHref?: string;
  hoverColor?: BACKGROUND_COLOR;
  borderRadius?: string;
  backgroundColor?: BACKGROUND_COLOR | string;
  type: 'image' | 'video';
};

const ImageHero = (props: ImageHeroProps) => {
  const {
    src,
    ratio = [],
    className = '',
    colorless = false,
    imageHref,
    hoverColor = BACKGROUND_COLORS.MOSS_20,
    borderRadius,
    type = 'image',
    backgroundColor = BACKGROUND_COLORS.MOSS_20,
  } = props;

  const [ratioWidth, ratioHeight] = ratio;
  const backgroundImageColor = useColor(backgroundColor);

  let imageBoxStyle = {};
  if (ratioWidth && ratioHeight) {
    const boxPaddingTop = (ratioHeight / ratioWidth) * 100;
    imageBoxStyle = { paddingTop: `${boxPaddingTop}%` };
  }

  const getHoverColor = (): string => {
    let backgroundHover = '';
    switch (hoverColor.split('-')[0]) {
      case 'moss':
        backgroundHover =
          'linear-gradient(180deg, #62AA7E -6.61%, #9CEB6B 41.1%, #F6FF50 100%)';
        break;
      case 'orca':
        backgroundHover =
          'linear-gradient(180deg, #8C74CF 0%, #5E95D1 26.59%, #B5E9FF 104.19%)';
        break;
      case 'fire':
        backgroundHover =
          'linear-gradient(180deg, #F15E7D -28.67%, #FFED57 83.83%)';
        break;
      default:
        break;
    }
    return backgroundHover;
  };

  const [hoverBackgroundColor, setHoverBackgroundColor] = useState<
    string | undefined
  >();

  const renderImage = () => {
    return (
      <>
        <div className={styles['image-box']} style={imageBoxStyle}>
          <div
            className={classNames(styles['image-main'], { 'fade-box': true })}
            data-image-src={src}
            style={{
              backgroundImage: `url("${src}")`,
              backgroundColor: backgroundImageColor,
            }}
          ></div>
        </div>
        {imageHref && (
          <a
            href={imageHref}
            target="_self"
            rel="noopener"
            className={styles.imageLink}
          />
        )}
      </>
    );
  };

  if (type === 'video') {
    return (
      <VimeoEmbed
        borderRadius={borderRadius}
        className={className}
        videoUrl={src}
        ratio={ratio}
      />
    );
  }
  const classNameImageHero = classNames(
    styles[`c-background-zoom`],
    className,
    colorless && styles.colorless,
    imageHref && styles.cursor,
    !src && styles.imageBreakDown
  );
  return (
    <div
      className={classNameImageHero}
      onMouseEnter={() =>
        setTimeout(() => setHoverBackgroundColor(getHoverColor()), 50)
      }
      onMouseLeave={() =>
        setTimeout(() => setHoverBackgroundColor(undefined), 250)
      }
      style={{
        background: `${hoverBackgroundColor ?? 'none'}`,
        borderRadius: `${borderRadius}`,
      }}
    >
      {renderImage()}
    </div>
  );
};
export default ImageHero;
