export const TYPES_LEAD_IMAGE = {
  VIDEO: 'video',
  IMAGE: 'image',
} as const;

export type TYPE_LEAD_IMAGE =
  typeof TYPES_LEAD_IMAGE[keyof typeof TYPES_LEAD_IMAGE];

export const BUTTON_SHARE_TYPES = {
  MESSENGER: 'messenger',
  INSTAGRAM: 'instagram',
  MAIL: 'mail',
  SMS: 'sms',
  COPY_LINK: 'copy-link',
} as const;

export type BUTTON_SHARE_TYPE =
  typeof BUTTON_SHARE_TYPES[keyof typeof BUTTON_SHARE_TYPES];

export const RATIOS = {
  '[1, 1]': '1:1',
  '[16, 9]': '16:9',
  '[9, 16]': '9:16',
  '[2, 1]': '2:1',
} as const;
export type RATIO = typeof RATIOS[keyof typeof RATIOS];
