import dynamic from 'next/dynamic';
import { BACKGROUND_COLORS, PHONE_COLORS } from '../../../../shared/colors';
import { BuilderConfig } from '../../../../types/Builder';
import { Props } from './flow-carousel-width-description';

const LazyFlowCarouselWidthDescription = dynamic(() =>
  import('./flow-carousel-width-description').then((module) => module as any)
);
export const FlowCarouselBuilderConfig = {
  name: 'Flow Carousel',
  inputs: [
    {
      name: 'phoneColor',
      type: 'string',
      defaultValue: PHONE_COLORS.MIDNIGHT,
      enum: Object.values(PHONE_COLORS),
    },
    {
      name: 'items',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'name', type: 'string', defaultValue: '' },
        { name: 'image', type: 'file', defaultValue: '' },
      ],
    },
    { name: 'carouselDescription', type: 'richText', defaultValue: '' },
    {
      name: 'carouselColor',
      type: 'string',
      defaultValue: BACKGROUND_COLORS.MOSS_20,
      enum: Object.values(BACKGROUND_COLORS),
    },
    { name: 'loop', type: 'boolean', defaultValue: true },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: LazyFlowCarouselWidthDescription as any,
  config: FlowCarouselBuilderConfig,
};

export default builderConfig;
