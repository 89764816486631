import classNames from 'classnames';
import React from 'react';
import ArticleTextWrap from '../article-text-wrap/article-text-wrap';
import SingleImage, { SingleImageProps } from '../single-image/single-image';
import styles from './two-col-image.module.scss';

export interface Props {
  images: SingleImageProps[];
  type: 'large' | 'small';
  title?: string;
  description?: string;
}

const TwoColImage = (props: Props) => {
  const { images, type = 'large', title, description } = props;
  const classNameTwoColImage = classNames(styles.twoColImage, styles[type]);
  const classNameTwoColWrapImage = classNames(
    styles.twoColImageWrap,
    styles[type]
  );
  const ratioImage = type === 'large' ? [538, 618] : [372, 165];

  const renderImageItem = (item: SingleImageProps) => {
    return (
      <div
        key={`image_two_col_key${item.imageUrl}`}
        className={styles.imageCol}
      >
        <SingleImage
          imageUrl={item.imageUrl}
          imageHref={item.imageHref}
          ratio={ratioImage}
          tagPosition="bottom"
          tag={item.tag?.name ? item.tag : undefined}
        />
      </div>
    );
  };
  return (
    <div className={classNameTwoColWrapImage}>
      {title && description && <ArticleTextWrap title={title} text={description || ''} titleType='titleNormal' />}
      <div className={classNameTwoColImage}>
        {images.map((item: SingleImageProps) => renderImageItem(item))}
      </div>
    </div>
  );
};

export default TwoColImage;
