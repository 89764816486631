import React from 'react';
import SingleImage from '../single-image/single-image';
import styles from './image-list.module.scss';
export interface Props {
  title?: string;
  description?: string;
  imageList: ImageListItem[];
}
interface ImageListItem {
  imageUrl: string;
  description: string;
  title: string;
}

const ImageList = (props: Props) => {
  const { imageList, title, description } = props;

  const renderImageItem = (item: ImageListItem) => {
    return (
      <li
        className={styles.imageListItem}
        key={`icon_list_item_key_${item.imageUrl}`}
      >
        <SingleImage
          imageUrl={item.imageUrl}
          ratio={[100, 100]}
          className="image-item-list"
        />
        <div className={styles.itemContent}>
          <p className={styles.itemTitle}>{item.title}</p>
          <div
            className={styles.itemDescription}
            dangerouslySetInnerHTML={{ __html: `${item.description}` }}
          />
        </div>
      </li>
    );
  };

  return (
    <div className={styles.imageListWrap}>
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      <ul className={styles.imageList}>
        {imageList.map((item: ImageListItem) => renderImageItem(item))}
      </ul>
    </div>
  );
};

export default ImageList;
