import { BuilderConfig } from '../../types/Builder';
import SeparationLine, { Props } from './separation-line';

export const DividerBuilderConfig = {
  name: 'Separation line',
};

const builderConfig: BuilderConfig<Props> = {
  component: SeparationLine,
  config: DividerBuilderConfig,
};

export default builderConfig;
