import { useEffect, useState } from 'react';
import { BACKGROUND_COLOR, BACKGROUND_COLORS } from '../shared/colors';

export function useColor(colorInput: BACKGROUND_COLOR | string) {
  const [color, setColor] = useState<string>('');
  useEffect(() => {
    if (
      Object.values(BACKGROUND_COLORS).includes(colorInput as BACKGROUND_COLOR)
    ) {
      const backgroundColorMap = {
        [BACKGROUND_COLORS.TRANSPARENT]: 'transparent',
        [BACKGROUND_COLORS.MINT]: '#b2fce4',
        [BACKGROUND_COLORS.MOSS_10]: '#EDF9DE',
        [BACKGROUND_COLORS.MOSS_20]: '#c1e7bb',
        [BACKGROUND_COLORS.MOSS_40]: '#98D79A',
        [BACKGROUND_COLORS.ORCA_20]: '#c0dbe9',
        [BACKGROUND_COLORS.ORCA_40]: '#5e95d1',
        [BACKGROUND_COLORS.ORCA_45]: '#488fe9',
        [BACKGROUND_COLORS.FIRE_20]: '#f8ae89',
        [BACKGROUND_COLORS.FIRE_40]: '#F3744B',
      };
      setColor(backgroundColorMap[colorInput as BACKGROUND_COLOR]);
    } else {
      setColor(colorInput);
    }
  }, [colorInput]);
  return color;
}
