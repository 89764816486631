import classNames from 'classnames';
import React from 'react';
import styles from './paragraph.module.scss';
export interface Props {
  h2?: string;
  title?: string;
  description?: string;
}

const Paragraph = (props: Props) => {
  const { h2, title, description } = props;
  const classH2 = classNames(styles.h2, !title && styles.paragraphNonH3);
  return (
    <div className={styles.paragraph}>
      {h2 && <h2 className={classH2}>{h2}</h2>}
      {title && <h3 className={styles.h3}>{title}</h3>}
      {description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};

export default Paragraph;
