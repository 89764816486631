import React, { useContext } from 'react';
import SwiperCore, { Grid, SwiperOptions } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PageColorCtx } from '../../../helpers/shareContext';
import { BACKGROUND_COLOR } from '../../../shared/colors';
import { Article } from '../../../types/Article';
import SingleImage from '../../single-image/single-image';
import styles from './circle-thumbnails.module.scss';

export interface Props {
  articles: Article[];
  hoverColor?: BACKGROUND_COLOR;
}
SwiperCore.use([Grid]);
const CircleThumbnails = (props: Props) => {
  const { articles, hoverColor } = props;
  const { pageColorCtx } = useContext(PageColorCtx);
  const swiperOption: SwiperOptions = {
    slidesPerView: 'auto',

    breakpoints: {
      320: { spaceBetween: 0, enabled: false },
      576: { spaceBetween: 56, enabled: true },
      768: { spaceBetween: 65, enabled: true },
    },
  };
  const renderArticleThumbnailItem = (article: Article) => {
    return (
      <SwiperSlide className={styles.articleItem} key={article.articleUrl}>
        <SingleImage
          imageUrl={article?.leadImage || ''}
          imageHref={article?.articleUrl}
          ratio={[270, 270]}
          colorless
          shape="circle"
          hoverBorderColor={(pageColorCtx as BACKGROUND_COLOR) || hoverColor}
        />
        <p className={styles.articleTitle}>{article.title}</p>
      </SwiperSlide>
    );
  };
  return (
    <div className={styles.circleThumbnails}>
      <div className={styles.circleThumbnailSwiperWrapper}>
        <Swiper className={styles.articleSwiperContainer} {...swiperOption}>
          {articles.map((article: Article) =>
            renderArticleThumbnailItem(article)
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default CircleThumbnails;
