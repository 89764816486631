import classNames from 'classnames';
import React from 'react';
import ArticleTextWrap from '../article-text-wrap/article-text-wrap';
import Icons, { IconType } from '../Icons';
import styles from './icon-list.module.scss';
export interface Props {
  contents: IconListItem[];
  type: 'large' | 'small';
  icon: IconType;
  title?: string;
  description?: string;
}
interface IconListItem {
  content: string;
  itemUrl?: string;
}
const IconList = (props: Props) => {
  const { contents, type, icon, title, description } = props;
  const classIconListWrapper = classNames(styles.iconList, styles[type]);

  const renderIconListItem = (item: IconListItem, index:number) => {
    return (
      <li
        className={styles.iconListItem}
        key={`icon_list_item_key_${item.itemUrl}+${index}`}
      >
        <Icons type={icon} />
        <a className={styles.itemContent} href={item.itemUrl}>
          {item.content}
        </a>
      </li>
    );
  };

  return (
    <div className={styles.iconListWrap}>
      {title && description && (
        <ArticleTextWrap
          titleType='titleNormal'
          title={title}
          text={description || ''}
          className={styles.iconListWrapHeader}
        />
      )}
      <ul className={classIconListWrapper}>
        {contents.map((item: IconListItem,index:number) => renderIconListItem(item, index))}
      </ul>
    </div>
  );
};

export default IconList;
