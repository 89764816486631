import React from 'react';
import { useColor } from '../../hook/useColor';
import { BACKGROUND_COLOR } from '../../shared/colors';
import styles from './background-full-width.module.scss';
interface Props {
  backgroundColor: BACKGROUND_COLOR | string;
  zIndex?: number;
}

const BackgroundFullWidth = (props: Props) => {
  const { backgroundColor, zIndex } = props;
  const color = useColor(backgroundColor);
  return (
    <div
      className={styles.backgroundFullWidth}
      style={{ backgroundColor: color, zIndex: zIndex }}
    ></div>
  );
};

export default BackgroundFullWidth;
