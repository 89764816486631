import { BACKGROUND_COLORS } from '../../../shared/colors';
import { BuilderConfig } from '../../../types/Builder';
import CallOutBanners, { Props } from './call-out-banner';

export const CallOutBannerBuilderConfig = {
  name: 'Call out banner',
  inputs: [
    {
      name: 'title',
      type: 'richText',
      defaultValue: '',
      required: true,
    },
    {
      name: 'listBenefit',
      type: 'list',
      defaultValue: [],
      required: true,
      subFields: [{ name: 'content', type: 'richText' }],
    },
    { name: 'imageUrl', type: 'file' },
    { name: 'linkDownLoad', type: 'string' },
    {
      name: 'paddingSize',
      type: 'string',
      enum: ['normal', 'small'],
      defaultValue: 'normal',
    },
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: BACKGROUND_COLORS.MOSS_20,
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: CallOutBanners,
  config: CallOutBannerBuilderConfig,
};

export default builderConfig;
