import React from 'react'
import styles from './article-text-large.module.scss'
interface Props {
  text: string
}

const ArticleTextLarge = (props: Props) => {
  const { text } = props
  return <div className={styles.articleTextLarge} dangerouslySetInnerHTML={{__html: text}}/>
}

export default ArticleTextLarge
