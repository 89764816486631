import React from 'react';
import { BACKGROUND_COLOR } from '../../shared/colors';
import { RATIO, RATIOS } from '../../shared/constants';
import { MEDIA_RATIO } from '../../types/Media';
import BackgroundFullWidth from '../background-full-width/background-full-width';
import styles from './vimeo-embed-warp.module.scss';

export interface Props {
  videoUrl: string;
  backgroundColor: BACKGROUND_COLOR;
  ratio: RATIO;
}

const VimeoEmbedWarp = (props: Props) => {
  const { videoUrl, backgroundColor, ratio=RATIOS['[16, 9]'] } = props;
  const [width, height] = MEDIA_RATIO[ratio];
  const vimeoVideoPaddingTop = (height / width) * 100;
  let styleWrap = {};
  if (width === 9 && height === 16) {
    styleWrap = { width: 343 };
  }
  return (
    <div style={styleWrap} className={styles.videoPlayerWrap}>
      <div
        className={styles.vimeoVideo}
        style={{ paddingTop: `${vimeoVideoPaddingTop}%` }}
      >
        <iframe
          src={videoUrl}
          width="538"
          height="618"
          frameBorder="0"
          allow="autoplay; muted; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>

      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default VimeoEmbedWarp;
