import OverlayCarousel, { Props } from '.';
import { BACKGROUND_COLORS } from '../../../../shared/colors';
import { BuilderConfig } from '../../../../types/Builder';

export const SuperpowersCarouselBuilderConfig = {
  name: 'Superpowers Carousel Overlay',
  inputs: [
    { name: 'title', type: 'richText' },
    { name: 'description', type: 'richText' },
    {
      name: 'header',
      type: 'object',
      subFields: [
        { name: 'category', type: 'string' },
        { name: 'number', type: 'string' },
        { name: 'url', type: 'url' },
      ],
    },
    {
      name: 'data',
      type: 'object',
      subFields: [
        { name: 'title', type: 'string' },
        { name: 'description', type: 'richText' },
        {
          name: 'items',
          type: 'list',
          defaultValue: [],
          subFields: [
            { name: 'name', type: 'richText', defaultValue: '' },
            { name: 'image', type: 'file', defaultValue: '' },
          ],
        },
      ],
    },
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: BACKGROUND_COLORS.FIRE_20,
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: OverlayCarousel,
  config: SuperpowersCarouselBuilderConfig,
};

export default builderConfig;
