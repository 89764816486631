import { BACKGROUND_COLORS } from '../../shared/colors';
import { BuilderConfig } from '../../types/Builder';
import VideoPlayerWarp, { Props } from './video-player-warp';

export const VideoPlayerBuilderConfig = {
  name: 'Video Player',
  inputs: [
    {
      name: 'videoUrl',
      type: 'string',
      required: true,
    },
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: VideoPlayerWarp,
  config: VideoPlayerBuilderConfig,
};

export default builderConfig;
