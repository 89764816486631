import React from 'react';
import { useMobile } from '../../../hook/useMobile';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import SingleImage, { SingleImageProps } from '../../single-image/single-image';
import styles from './merchant-banner.module.scss';
export interface Props {
  title: string;
  description: string;
  merchantLogo: string;
  merchantImage: SingleImageProps;
}
const MerchantBanner = (props: Props) => {
  const { title, description, merchantLogo, merchantImage } = props;
  const isMobile = useMobile();
  const colorlessImage = isMobile ? false : true;
  return (
    <div className={styles.merchantBanner}>
      <div className={styles.infoDetail}>
        <div className={styles.merchantLogoImage}>
          <SingleImage
            imageUrl={merchantLogo}
            imageHref={merchantImage?.imageHref}
            ratio={[118, 34]}
            isHover={false}
            borderRadius={0}
          />
        </div>

        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className={styles.imageContainer}>
        <div className={styles.buttonRight}>
          <Button
            icon={<Icons type={IconType.ArrowRight} />}
            type="black"
            size="default"
          ></Button>
        </div>
        <div className={styles.imageBox}>
          <SingleImage
            tagPosition="bottom"
            tag={merchantImage?.tag}
            imageUrl={merchantImage?.imageUrl}
            imageHref={merchantImage?.imageHref}
            ratio={[425, 307]}
            colorless={colorlessImage}
            isHover={false}
          />
        </div>
      </div>
      <a className={styles.merchantLink} href={merchantImage?.imageHref}></a>
    </div>
  );
};

export default MerchantBanner;
