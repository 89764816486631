import React from 'react';
import styles from './article-text-large-wrap.module.scss';
export interface Props {
  text: string;
}

const ArticleTextLargeWrap = (props: Props) => {
  const { text } = props;
  return (
    <div
      className={styles.articleTextLargeWrap}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default ArticleTextLargeWrap;
