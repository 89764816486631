import { BuilderConfig } from '../../types/Builder';
import TwoColImage, { Props } from './two-col-image';

export const TwoColImageBuilderConfig = {
  name: 'Two col Image',
  inputs: [
    { name: 'title', type: 'string' },
    {
      name: 'description',
      type: 'string',
    },
    {
      name: 'images',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'imageUrl', type: 'file', require: true },
        { name: 'imageHref', type: 'string' },
        {
          name: 'tag',
          type: 'object',
          subFields: [
            { name: 'name', type: 'string' },
            { name: 'url', type: 'string' },
          ],
        },
      ],
    },
    {
      name: 'type',
      type: 'string',
      defaultValue: 'large',
      enum: ['large', 'small'],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: TwoColImage,
  config: TwoColImageBuilderConfig,
};

export default builderConfig;
