import { BuilderConfig } from '../../types/Builder';
import ArticleHeader, { Props } from './article-header';

export const ArticleHeaderBuilderConfig = {
  name: 'Article Header',
  inputs: [
    {
      name: 'title',
      type: 'richText',
      require: true,
      defaultValue: '',
    },
    {
      name: 'partnerLogo',
      type: 'file',
    },
    {
      name: 'tags',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string' },
        { name: 'url', type: 'string' },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleHeader,
  config: ArticleHeaderBuilderConfig,
};

export default builderConfig;
