import { BuilderConfig } from '../../types/Builder';
import ArticleTextWrap, { Props } from './article-text-wrap';
export const ArticleTextBuilderConfig = {
  name: 'Article Text',
  inputs: [
    { name: 'title', type: 'string' },
    {
      name: 'titleType',
      type: 'string',
      defaultValue: 'titleNormal',
      enum: ['titleLarge', 'titleNormal'],
    },
    {
      name: 'text',
      type: 'richText',
      require: true,
    },
    { name: 'verticalLine', type: 'boolean', defaultValue: false },
    { name: 'colorless', type: 'boolean', defaultValue: false },
    {
      name: 'textSize',
      type: 'string',
      defaultValue: 'normal',
      enum: ['normal', 'small'],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleTextWrap,
  config: ArticleTextBuilderConfig,
};

export default builderConfig;
