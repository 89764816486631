export const onSetTranslate = (swiper) => {
  const { width: swiperWidth, slides, slidesSizesGrid } = swiper;
  const params = {
    rotate: 10,
    depth: 0,
    stretch: 0,
    scale: 1,
    modifier: 1,
  };
  const transform = swiper.translate;
  const center = -transform + swiperWidth / 2;
  const rotate = -params.rotate;
  // Each slide offset from center
  for (let i = 0, length = slides.length; i < length; i += 1) {
    const $slideEl = slides.eq(i);
    const slideSizeWidth = slidesSizesGrid[i];
    const slideOffset = $slideEl[0].swiperSlideOffset;
    const offsetMultiplier =
      ((center - slideOffset - slideSizeWidth / 2) / slideSizeWidth) *
      params.modifier;

    let rotateY = rotate * offsetMultiplier;
    if (Math.abs(rotateY) < 0.001) rotateY = 0;

    const offsetMultiplierInt = Math.round(offsetMultiplier);
    let translateX = 0;
    if (offsetMultiplierInt === 2) {
      translateX = -160; // trick to hide
    }
    if (offsetMultiplierInt === -2) {
      translateX = 160; // trick to hide
    }
    if (offsetMultiplierInt < 0) {
      $slideEl[0].style.transformOrigin = 'left bottom';
    }
    if (offsetMultiplierInt > 0) {
      $slideEl[0].style.transformOrigin = 'right bottom';
    }
    const translateY = 0;
    const translateZ = 0;
    const scale = 1;
    const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px) rotate(${rotateY}deg) scale(${scale})`;
    $slideEl.transform(slideTransform);
    $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
  }
};

export const onSetTransition = (swiper, duration) => {
  swiper.slides
    .transition(duration)
    .find(
      '.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left'
    )
    .transition(duration);
};
