import Link from 'next/link';
import React from 'react';
import Button from '../Button';
import Icons, { IconType } from '../Icons';
import styles from './button-back.module.scss';
export interface Props {
  backUrl: string;
}

const ButtonBack = (props: Props) => {
  const { backUrl } = props;

  return (
    <Link href={backUrl || '/'}>
      <a className={styles.buttonBack}>
        <Button
          onClick={() => {
            if (backUrl && backUrl !== '') {
              return;
            }
            if (typeof window === 'undefined') {
              return;
            }
            window.history.back();
          }}
          size="medium"
          type="black"
          icon={<Icons type={IconType.ArrowLeft} />}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          Back
        </Button>
      </a>
    </Link>
  );
};

export default ButtonBack;
