import React, { useContext } from 'react';
import { PageArticleCtx, PageColorCtx } from '../../helpers/shareContext';
import { useWindowSize } from '../../hook/useWindowSize';
import { BACKGROUND_COLOR } from '../../shared/colors';
import { Article } from '../../types/Article';
import BackgroundFullWidth from '../background-full-width/background-full-width';
import Button from '../Button';
import Icons, { IconType } from '../Icons';
import CarouselHeader from '../money-site-carousel/carousel-header';
import SingleImage from '../single-image/single-image';
import styles from './money-site-explore.module.scss';
export interface Props {
  articles: Article[];
  title: string;
  backgroundColor: BACKGROUND_COLOR;
  categoryName: string;
  cardNumber: string;
  hoverColor?: BACKGROUND_COLOR;
}

const MoneySiteExplore = (props: Props) => {
  const {
    articles,
    backgroundColor,
    title,
    cardNumber,
    categoryName,
    hoverColor,
  } = props;
  const { width = 0 } = useWindowSize();
  const {  pageColorCtx } = useContext(PageColorCtx);
  const { articleCtxData } = useContext(PageArticleCtx);
  const hoverImageColor = (pageColorCtx ||
    articleCtxData?.pageColor ||
    hoverColor) as BACKGROUND_COLOR;
  const imageRatioTemplate = [
    [427, 428],
    [427, 550],
    [428, 344],
  ];
  const renderArticleItem = (article: Article, index: number) => {
    const ratioLeadImage = width < 576 ? [343, 208] : imageRatioTemplate[index];

    return (
      <div className={styles.articleItem} key={article.articleUrl}>
        <SingleImage
          imageUrl={article?.leadImage || ''}
          ratio={ratioLeadImage}
          imageHref={article.articleUrl}
          colorless
          tagPosition="top"
          tagSize="normal"
          tag={article.categories[0]}
          shape="square"
          isHover
          hoverBorderColor={hoverImageColor}
        />
        <p className={styles.articleTitle}>{article.title}</p>
        <div
          className={styles.articleDescription}
          dangerouslySetInnerHTML={{
            __html: article.description,
          }}
        />
        <Button
          className={styles.buttonReadMore}
          size="large"
          type="link"
          shape="circle"
          icon={<Icons type={IconType.ArrowRight} stroke="#000000" />}
        >
          Read more
        </Button>
      </div>
    );
  };
  return (
    <div className={styles.moneySiteExplore}>
      <CarouselHeader category={categoryName} number={cardNumber} />
      <p className={styles.title}>{title}</p>
      <div className={styles.articleExplore}>
        {articles.map((article: Article, index: number) =>
          renderArticleItem(article, index)
        )}
      </div>
      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default MoneySiteExplore;
