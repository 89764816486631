import React from 'react';
import BackgroundFullWidth from '../background-full-width/background-full-width';
import CarouselHeader from '../money-site-carousel/carousel-header';
import styles from './section-background.module.scss';
export interface Props {
  backgroundColor: string;
  children?: JSX.Element;
  categoryName: string;
  number: string;
  title?: string;
  description?: string;
}

const SectionBackground = (props: Props) => {
  const { categoryName, backgroundColor, number, description, title } = props;

  const renderCarouselHeader = () => {
    if (!categoryName) {
      return null;
    }
    return (
      <div className={styles.carouselHeader}>
        <CarouselHeader category={categoryName} number={number} />
        {title && <p className={styles.carouselHeaderTitle}>{title}</p>}
        {description && description !== '' && (
          <div
            className={styles.carouselHeaderDescription}
            dangerouslySetInnerHTML={{ __html: `${description}` }}
          />
        )}
      </div>
    );
  };
  return (
    <div className={styles.moneySiteCarouselWarp}>
      {renderCarouselHeader()}
      {props.children}
      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default SectionBackground;
