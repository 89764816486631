import { BuilderConfig } from '../../types/Builder';
import ArticleQuote, { Props } from './article-quote';

export const ArticleQuoteBuilderConfig = {
  name: 'Article Quote',
  inputs: [
    {
      name: 'quote',
      type: 'string',
      require: true,
    },
    {
      name: 'authorName',
      type: 'string',
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleQuote,
  config: ArticleQuoteBuilderConfig,
};

export default builderConfig;
