import classNames from 'classnames';
import React from 'react';
import { Tag } from '../../types/Tag';
import Tags from '../tags/tags';
import styles from './article-header.module.scss';
export interface Props {
  tags?: Tag[];
  title: string;
  partnerLogo?: string;
}

const ArticleHeader = (props: Props) => {
  const { tags, title, partnerLogo } = props;

  const classTitleContainer = classNames(
    styles.titleContainer,
    !tags && styles.headerNoTags
  );
  const renderPartnerShip = () => {
    if (!partnerLogo) {
      return null;
    }
    return (
      <div className={styles.partnership}>
        In partnership with
        <div
          className={styles.logoPartner}
          style={{ backgroundImage: `url("${partnerLogo}")` }}
        />
      </div>
    );
  };
  return (
    <div className={styles.articleTitle}>
      <div className={classTitleContainer}>
        {tags && <Tags size="normal" {...{ tags }} />}
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {renderPartnerShip()}
      </div>
    </div>
  );
};

export default ArticleHeader;
