import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, useRef, useState } from 'react';
import FlowCarousel, {
  FlowCarouselColor,
  FlowCarouselHandle,
  PhoneColorType,
} from '..';
import { useWindowSize } from '../../../../hook/useWindowSize';
import { BACKGROUND_COLOR } from '../../../../shared/colors';
import BackgroundFullWidth from '../../../background-full-width/background-full-width';
import Button from '../../../Button';
import Icons, { IconType } from '../../../Icons';
import { CarouselItemType } from '../../carousel';
import CarouselHeader, { CarouselHeaderType } from '../../carousel-header';
import styles from './overlay.module.scss';

export type Props = {
  mode?: 'center' | 'right';
  phoneColor?: PhoneColorType;
  header?: CarouselHeaderType;
  color?: FlowCarouselColor;
  data: {
    title?: string;
    description?: string;
    items?: CarouselItemType[];
  };
  backgroundColor?: BACKGROUND_COLOR;
};

const OverlayCarousel: FC<Props> = ({
  mode,
  data,
  phoneColor,
  header,
  backgroundColor,
  ...props
}) => {
  const { width = 0 } = useWindowSize();
  const [selectedItem, setSelectedItem] = useState<CarouselItemType | null>(
    null
  );
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false);
  const carouselRef = useRef<FlowCarouselHandle>(null);

  const onItemChange = (item: CarouselItemType) => {
    setSelectedItem(item);
  };
  const moneyFlowUrl = '/moneyflow';
  return (
    <div className={styles.overlayFlowCarousel}>
      {header ? (
        <div className={styles.overlayFlowCarouselHeader}>
          <CarouselHeader {...header} />
        </div>
      ) : null}
      <div className={styles.overlayFlowCarouselContent}>
        <div className={styles.overlay}>
          <div className={styles.overlayTitle}>
            <Link href={moneyFlowUrl}>
              <a>
                {data.title}
                <span className={styles.overlayTitleButton}>
                  <Button
                    type="black"
                    size={width > 979 ? 'medium' : 'small'}
                    icon={<Icons type={IconType.ArrowRight} />}
                  />
                </span>
              </a>
            </Link>
          </div>
          <div
            className={styles.overlayDescription}
            dangerouslySetInnerHTML={{ __html: data.description || '' }}
          />
          <div className={styles.overlayItems}>
            {data.items?.map((item, index) => (
              <div
                key={index}
                className={classNames(styles.overlayItem, {
                  [styles.overlayItemHide]:
                    !isCollapseOpen &&
                    index !== 0 &&
                    (data.items?.length || 0) > 6,
                })}
              >
                <span
                  className={classNames({
                    [styles.overlayItemActive]:
                      selectedItem?.name === item.name &&
                      selectedItem.image === item.image,
                  })}
                  onClick={() => {
                    carouselRef.current?.slideToLoop(index);
                    onItemChange(item);
                  }}
                >
                  <div
                    className={styles.textInnerHtml}
                    dangerouslySetInnerHTML={{ __html: item.name }} //TODO
                  />
                </span>
                {(data.items?.length || 0) > 6 && index === 0 && (
                  <Button
                    style={{ right: 0 }}
                    type="black"
                    size={'small'}
                    icon={<Icons type={IconType.Down} />}
                    onClick={() => setIsCollapseOpen((val) => !val)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.carousel}>
          <FlowCarousel
            ref={carouselRef}
            mode={mode}
            items={data.items || []}
            onItemChange={onItemChange}
            phoneColor={phoneColor}
            {...props}
          />
        </div>
      </div>
      <BackgroundFullWidth backgroundColor={backgroundColor || ''} />
    </div>
  );
};

export default OverlayCarousel;
