import { BACKGROUND_COLORS } from '../../shared/colors';
import { BuilderConfig } from '../../types/Builder';
import MoneySiteExplore, { Props } from './money-site-explore';

export const MoneySiteSliderWrapBuilderConfig = {
  name: 'Money Site Explore',
  inputs: [
    {
      name: 'categoryName',
      type: 'string',
      defaultValue: '',
      required: true,
    },
    {
      name: 'cardNumber',
      type: 'string',
      defaultValue: '',
      required: true,
    },

    {
      name: 'title',
      type: 'string',
      defaultValue: '',
      required: true,
    },

    {
      name: 'articles',
      type: 'list',
      defaultValue: [],
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '',
          required: true,
        },
        {
          name: 'description',
          type: 'richText',
        },
        {
          name: 'articleUrl',
          type: 'string',
          defaultValue: '',
          required: true,
        },
        {
          name: 'leadImage',
          type: 'file',
          defaultValue: '',
          required: true,
        },
        {
          name: 'categories',
          type: 'list',
          defaultValue: [],
          required: true,
          subFields: [
            { name: 'name', defaultValue: '', type: 'string', required: true },
            { name: 'url', type: 'string', defaultValue: '' },
          ],
        },
      ],
    },
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: BACKGROUND_COLORS.MOSS_20,
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: MoneySiteExplore,
  config: MoneySiteSliderWrapBuilderConfig,
};

export default builderConfig;
