import { BuilderConfig } from '../../../types/Builder';
import MerchantBanner, { Props } from './merchant-banner';

export const MerchantBannerBuilderConfig = {
  name: 'Merchant banner',
  inputs: [
    { name: 'merchantLogo', type: 'file' },
    {
      name: 'title',
      type: 'richText',
      defaultValue: '',
      required: true,
    },
    { name: 'description', type: 'richText' },
    {
      name: 'merchantImage',
      type: 'object',
      subFields: [
        { name: 'imageUrl', type: 'file' },
        { name: 'imageHref', type: 'string' },
        {
          name: 'tag',
          type: 'object',
          subFields: [
            { name: 'name', type: 'string' },
            {
              name: 'url',
              type: 'string',
            },
          ],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: MerchantBanner,
  config: MerchantBannerBuilderConfig,
};

export default builderConfig;
