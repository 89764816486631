import { BuilderConfig } from '../../types/Builder';
import ArticleMeta, { Props } from './article-meta';

export const ArticleMetaBuilderConfig = {
  name: 'Article Meta',
  inputs: [
    {
      name: 'metaText',
      type: 'richText',
      require: true,
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleMeta,
  config: ArticleMetaBuilderConfig,
};

export default builderConfig;
