import { BuilderConfig } from '../../types/Builder';
import ButtonBack, { Props } from './button-back';
export const ArticleTextBuilderConfig = {
  name: 'Button Back',
  inputs: [{ name: 'backUrl', type: 'string' }],
};

const builderConfig: BuilderConfig<Props> = {
  component: ButtonBack,
  config: ArticleTextBuilderConfig,
};

export default builderConfig;
