import React, { useContext } from 'react';
import { PageArticleCtx } from '../../helpers/shareContext';
import { BUTTON_SHARE_TYPE, BUTTON_SHARE_TYPES } from '../../shared/constants';
import { Tag } from '../../types/Tag';
import ArticleTextLarge from '../article-text-large/article-text-large';
import Button from '../Button';
import Icons, { IconType } from '../Icons';
import MailTo from '../mail-to/mail-to';
import Tags from '../tags/tags';
import styles from './article-meta.module.scss';

declare global {
  interface Window {
    clipboardData: any;
  }
}

if (typeof window !== 'undefined') {
  window.clipboardData = window.clipboardData || {};
}
interface PopupCenter {
  url: string;
  title: string;
  popupWidth: number;
  popupHeight: number;
}
export interface Props {
  metaText: string;
}
type ButtonMeta = {
  icon: IconType;
  type: BUTTON_SHARE_TYPE;
};

const copyToClipboard = (text: string): boolean => {
  if (
    typeof window !== 'undefined' &&
    window?.clipboardData &&
    window?.clipboardData.setData
  ) {
    return window?.clipboardData.setData('Text', text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy');
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return false;
};

const handlePopupCenter = ({
  url,
  title,
  popupWidth,
  popupHeight,
}: PopupCenter) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
  const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${popupWidth / systemZoom}, 
      height=${popupHeight / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );
  if (window?.focus as any) newWindow?.focus();
};
const getUrlShare = (type: string, urlShare: string): string => {
  switch (type) {
    case BUTTON_SHARE_TYPES.MESSENGER:
      return `fb-messenger://share/?link=${urlShare}`;
    case BUTTON_SHARE_TYPES.SMS:
      return `sms:?&body=${urlShare}`;
    case BUTTON_SHARE_TYPES.INSTAGRAM:
      return urlShare;
    default:
      return urlShare;
  }
};

const getTooltipMessage = (type: string): string => {
  switch (type) {
    case BUTTON_SHARE_TYPES.MESSENGER:
      return 'Share on Messenger';
    case BUTTON_SHARE_TYPES.INSTAGRAM:
      return 'Share on Instagram';
    case BUTTON_SHARE_TYPES.SMS:
      return 'Share on SMS';
    case BUTTON_SHARE_TYPES.MAIL:
      return 'Share on mail';
    case BUTTON_SHARE_TYPES.COPY_LINK:
      return 'Click to copy';
    default:
      return 'Click to copy';
  }
};
const ArticleMeta = (props: Props) => {
  const { metaText } = props;
  const { articleCtxData } = useContext(PageArticleCtx);
  const buttons = [
    // { type: BUTTON_SHARE_TYPES.MESSENGER, icon: IconType.Messenger },
    // { type: BUTTON_SHARE_TYPES.SMS, icon: IconType.SMS },
    // { type: BUTTON_SHARE_TYPES.INSTAGRAM, icon: IconType.Instagram },
    { type: BUTTON_SHARE_TYPES.MAIL, icon: IconType.Mail },
    { type: BUTTON_SHARE_TYPES.COPY_LINK, icon: IconType.ShareLink },
  ];

  const formatDate = (date: string) => {
    const dateFormat = new Date(date);
    return dateFormat
      .toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      .replace(/ /, ' ');
  };

  const dateFormat = formatDate(articleCtxData?.publishedDate);

  const dateTimeMeta: Tag[] = [
    { name: dateFormat, url: '' },
    { name: `${articleCtxData.timeToRead} minutes`, url: '' },
  ];

  const renderButtonMeta = (button: ButtonMeta) => {
    if (button.type === BUTTON_SHARE_TYPES.MAIL) {
      const bodyMail =
        `${articleCtxData.title}` + '\n' + `${window?.location?.href}`;
      return (
        <div className={styles.buttonWarp} key={button.type}>
          <MailTo body={bodyMail} subject={articleCtxData?.title || ''}>
            <Button
              type="black"
              size="default"
              shape="circle"
              icon={<Icons type={button.icon} stroke="#B2FCE4" />}
              style={{ height: '40px', width: '40px', padding: '8px' }}
            />
            <span className={styles.tooltip}>
              {getTooltipMessage(button.type)}
            </span>
          </MailTo>
        </div>
      );
    }
    return (
      <div className={styles.buttonWarp} key={button.type}>
        <Button
          onClick={() => {
            const linkShare = getUrlShare(button.type, window?.location?.href);
            button.type === BUTTON_SHARE_TYPES.COPY_LINK
              ? copyToClipboard(linkShare)
              : handlePopupCenter({
                  url: linkShare,
                  title: getTooltipMessage(button.type),
                  popupWidth: 400,
                  popupHeight: 300,
                });
          }}
          className={
            button.icon === IconType.Instagram
              ? 'btn-share-instagram'
              : undefined
          }
          type="black"
          size="default"
          shape="circle"
          icon={<Icons type={button.icon} stroke="#B2FCE4" />}
          style={{ height: '40px', width: '40px', padding: '8px' }}
        />
        <span className={styles.tooltip}>{getTooltipMessage(button.type)}</span>
      </div>
    );
  };
  return (
    <div className={styles.articleMeta}>
      <ArticleTextLarge text={metaText} />
      <div className={styles.articleMetaContainer}>
        <div className={styles.articleMetaTags}>
          <Tags tags={dateTimeMeta} size="large" />
        </div>
        {buttons.length > 0 && (
          <div className={styles.shareLinks}>
            {buttons.map((button: ButtonMeta) => renderButtonMeta(button))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleMeta;
