import { BACKGROUND_COLORS } from '../../shared/colors';
import { RATIOS } from '../../shared/constants';
import { BuilderConfig } from '../../types/Builder';
import VimeoEmbedWarp, { Props } from './video-embed-warp';

export const VimeoEmbedWrapBuilderConfig = {
  name: 'Vimeo Embed',
  inputs: [
    {
      name: 'videoUrl',
      type: 'string',
      required: true,
    },
    {
      name: 'ratio',
      type: 'string',
      defaultValue: RATIOS['[9, 16]'],
      enum: Object.values(RATIOS),
    },
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: VimeoEmbedWarp,
  config: VimeoEmbedWrapBuilderConfig,
};

export default builderConfig;
