import classNames from 'classnames';
import React from 'react';
import ArticleTextLarge from '../article-text-large/article-text-large';
import styles from './article-text-wrap.module.scss';
export interface Props {
  text: string;
  title?: string;
  className?: string;
  titleType?: 'titleLarge' | 'titleNormal';
  colorless?: boolean;
  verticalLine?: boolean;
  textSize?: 'normal' | 'small';
}

const ArticleTextWrap = (props: Props) => {
  const {
    text,
    title,
    className,
    titleType,
    verticalLine = false,
    textSize = 'normal',
    colorless = false,
  } = props;
  const classNameTextWrap = classNames(styles.articleTextWrap, className);
  const renderTitle = () => {
    if (!title) {
      return null;
    }
    if (titleType === 'titleLarge') {
      return (
        <div className={styles.titleLarge}>
          <ArticleTextLarge text={title} />
        </div>
      );
    }
    return <h2 className={styles.titleBold}>{title}</h2>;
  };
  const classNameText = classNames(
    styles.articleText,
    styles[textSize],
    colorless && styles.colorless
  );
  return (
    <div className={classNameTextWrap}>
      {renderTitle()}
      {verticalLine && <div className={styles.verticalLine}></div>}
      <div
        className={classNameText}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default ArticleTextWrap;
