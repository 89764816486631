import classNames from 'classnames';
import React, { FC } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import { CarouselItemType } from '../carousel';
import { onSetTransition, onSetTranslate } from './CircleEffect';
import styles from './superpowers-carousel.module.scss';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
SwiperCore.use([Pagination, Navigation]);

export type SuperpowersCarouselType = {
  items: CarouselItemType[];
  onItemChange?: (item: CarouselItemType, index: number) => void;
};

const SuperpowersCarousel: FC<SuperpowersCarouselType> = ({
  items,
  onItemChange,
}) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div className={styles.carouselWrapper}>
      <Swiper
        className={styles.swiperContainer}
        grabCursor
        keyboard
        centeredSlides={true}
        watchSlidesProgress={true}
        loop={true}
        slidesPerView={'auto'}
        spaceBetween={0}
        freeMode={false}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSetTranslate={onSetTranslate}
        onSetTransition={onSetTransition}
        onSlideChange={(swiper) => {
          if (onItemChange) {
            const currentSlideIdx = swiper.realIndex;
            onItemChange(items[currentSlideIdx], swiper.realIndex);
          }
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
             {/* eslint-disable-next-line @next/next/no-img-element */}
            <img alt="" data-src={item.image} className="lazyload"/>
          </SwiperSlide>
        ))}
      </Swiper>
      <Button
        ref={navigationPrevRef}
        className={classNames(
          styles.carouselNavigate,
          styles.carouselNavigatePrev
        )}
        type="olive"
        icon={<Icons type={IconType.Left} />}
        size="large"
      />
      <Button
        ref={navigationNextRef}
        className={classNames(
          styles.carouselNavigate,
          styles.carouselNavigateNext
        )}
        type="olive"
        icon={<Icons type={IconType.Right} />}
        size="large"
      />
    </div>
  );
};

export default SuperpowersCarousel;
