import dynamic from 'next/dynamic';
import { Props } from '.';
import { BuilderConfig } from '../../../../types/Builder';
import { IconType } from '../../../Icons';
const LazyFeatureCarouselOverlay = dynamic(() =>
  import('./index').then((module) => module as any)
);
export const FeatureYouOverlayBuilderConfig = {
  name: 'Feature You Overlay',
  inputs: [
    { name: 'description', type: 'richText' },
    {
      name: 'header',
      type: 'object',
      subFields: [
        { name: 'category', type: 'string' },
        { name: 'number', type: 'string' },
        { name: 'url', type: 'url' },
      ],
    },
    {
      name: 'rightItems',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'content', type: 'richText' },
        {
          name: 'Icon',
          type: 'string',
          defaultValue: IconType.FourPointedStart,
          enum: Object.values(IconType),
        },
      ],
    },
    {
      name: 'frames',
      type: 'list',
      subFields: [
        { name: 'src', type: 'file', require: true },
        {
          name: 'type',
          type: 'string',
          defaultValue: 'frameOne',
          enum: [
            'frameOne',
            'frameTwo',
            'frameThree',
            'frameFour',
            'frameFive',
          ],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: LazyFeatureCarouselOverlay as any,
  config: FeatureYouOverlayBuilderConfig,
};

export default builderConfig;
