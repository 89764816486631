import { BuilderConfig } from '../../types/Builder';
import Paragraph, { Props } from './paragraph';

export const ParagraphBuilderConfig = {
  name: 'Paragraph',
  inputs: [
    { name: 'h2', type: 'string' },
    { name: 'title', type: 'string' },
    { name: 'description', type: 'richText' },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: Paragraph,
  config: ParagraphBuilderConfig,
};

export default builderConfig;
