import { createContext } from 'react';
import { ArticleCtxProps } from '../types/Article';

interface IContextPageColorProps {
  pageColorCtx: string;
  setPageColorCtx: (category: string) => void;
}
export const PageColorCtx = createContext({} as IContextPageColorProps);

interface IContextArticleProps {
  articleCtxData: ArticleCtxProps;
  setPArticleCtxData: (article: ArticleCtxProps) => void;
}
export const PageArticleCtx = createContext({} as IContextArticleProps);
