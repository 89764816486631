import React from 'react';
import { Tag } from '../../types/Tag';
import CategoryTag from '../category-tag/category-tag';
import styles from './tags.module.scss';

interface Props {
  tags: Tag[];
  size: 'large' | 'normal';
}

const Tags = (props: Props) => {
  const { tags, size } = props;
  return (
    <div className={styles.tagsContainer}>
      {tags.map((tag: Tag) => (
        <CategoryTag key={`tag_${tag.url}`} {...{ tag }} size={size} />
      ))}
    </div>
  );
};

export default Tags;
