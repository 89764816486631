import React from 'react';
import styles from './separation-line.module.scss';

export interface Props {}

const SeparationLine = () => {
  return <div className={styles.separationLine}></div>;
};

export default SeparationLine;
