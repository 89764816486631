import { BuilderConfig } from '../../types/Builder';
import Divider, { Props } from './divider';

export const DividerBuilderConfig = {
  name: 'Divider',
  inputs: [{ name: 'height', type: 'number' }],
};

const builderConfig: BuilderConfig<Props> = {
  component: Divider,
  config: DividerBuilderConfig,
};

export default builderConfig;
