import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './vimeo-embed.module.scss';

export interface Props {
  videoUrl: string;
  ratio?: number[];
  className?: string;
  loop?: boolean;
  muted?: boolean;
  controls?: boolean;
  borderRadius?: string;
}

const VimeoEmbed: React.FC<Props> = (props: Props) => {
  const {
    videoUrl,
    ratio = [],
    className,
    loop = true,
    muted = true,
    controls = false,
    borderRadius,
  } = props;
  const [loading, setLoading] = useState(true);
    const handleCanPlay = () => {
      setLoading(false);
    };
  const renderVidPlayer = () => {
    const [width, height] = ratio;
    let vimeoVideoPaddingTop = 0;
    if (width && height) {
      vimeoVideoPaddingTop = (height / width) * 100;
    }
  const classNameVimeoEmbed = classNames(
    styles.vimeoVideo,
    className,
    loading && styles['c-video-loading'],
    className
  );
    return (
      <div
        className={classNameVimeoEmbed}
        style={{
          paddingTop: `${vimeoVideoPaddingTop}%`,
          borderRadius: `${borderRadius}`,
        }}
      >
        <video
          onCanPlay={handleCanPlay}
          className={styles.CVideo}
          loop={loop}
          controls={controls}
          autoPlay
          muted={muted}
          playsInline
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        <div className={styles['c-video-loader']}></div>
      </div>
    );
  };

  const classNamesWarper = classNames(styles.vimeoEmbedContainer, className);
  return <div className={classNamesWarper}>{renderVidPlayer()}</div>;
};

export default VimeoEmbed;
