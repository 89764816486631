import React, { FC, useState } from 'react';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import SuperpowersCarousel from '..';
import { useWindowSize } from '../../../../hook/useWindowSize';
import { BACKGROUND_COLOR } from '../../../../shared/colors';
import BackgroundFullWidth from '../../../background-full-width/background-full-width';
import Button from '../../../Button';
import Icons, { IconType } from '../../../Icons';
import { CarouselItemType } from '../../carousel';
import CarouselHeader, { CarouselHeaderType } from '../../carousel-header';
import styles from './overlay.module.scss';
import Link from 'next/link';
import classNames from 'classnames';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
export type Props = {
  header?: CarouselHeaderType;
  data: {
    title?: string;
    description?: string;
    items: CarouselItemType[];
  };
  title?: string;
  description?: string;
  backgroundColor?: BACKGROUND_COLOR;
};

const OverlayCarousel: FC<Props> = ({
  header,
  data,
  backgroundColor,
  description,
}) => {
  const { width = 0 } = useWindowSize();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const onItemChange = (item: CarouselItemType, index: number) => {
    swiper?.slideTo(index, 1000);
  };
  const superPowerUrl = '/super-powers';
  return (
    <div className={styles.overlayCarousel}>
      {header ? (
        <div className={styles.overlayCarouselHeader}>
          <CarouselHeader {...header} />
        </div>
      ) : null}

      <div className={styles.overlay}>
        <div className={styles.overlayBox}>
          <div className={styles.overlayTitle}>
            <Link href={superPowerUrl}>
              <a>
                {/* TODO    <span dangerouslySetInnerHTML={{__html: title||''}}/></span> */}
                Hack your &#10; spending habits.
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  className={classNames(styles.overlayTitleImage, {lazyload:true})}
                  data-src="/images/Fire.png"
                  alt=""
                  
                />
                <span className={styles.overlayTitleButton}>
                  <Button
                    type="black"
                    size={width > 979 ? 'medium' : 'small'}
                    icon={<Icons type={IconType.ArrowRight} />}
                  />
                </span>
              </a>
            </Link>
          </div>

          <div
            className={styles.overlayDescription}
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
        </div>
      </div>

      <div className={styles.carousel}>
        <SuperpowersCarousel items={data.items} onItemChange={onItemChange} />
        <div id="item-name" className={styles.overlayCarouselNames}>
          <Swiper direction="vertical" onSwiper={setSwiper}>
            {data.items.map((item, index) => (
              <SwiperSlide key={index}>
                <div dangerouslySetInnerHTML={{ __html: item.name }} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <BackgroundFullWidth backgroundColor={backgroundColor || ''} />
    </div>
  );
};

export default OverlayCarousel;
