import { BuilderConfig } from '../../../types/Builder';
import CircleThumbnails, { Props } from './circle-thumbnails';

export const FlowCarouselHeaderBuilderConfig = {
  name: 'Circle thumbnails',
  inputs: [
    {
      name: 'articles',
      type: 'list',
      defaultValue: [],
      required: true,
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '',
          required: true,
        },
        {
          name: 'description',
          type: 'richText',
          defaultValue: '',
          required: true,
        },
        {
          name: 'articleUrl',
          type: 'string',
          defaultValue: '',
          required: true,
        },
        {
          name: 'leadImage',
          type: 'file',
          defaultValue: '',
          required: true,
        },
        {
          name: 'categories',
          type: 'list',
          defaultValue: [],
          required: true,
          subFields: [
            { name: 'name', defaultValue: '', type: 'string', required: true },
            { name: 'url', type: 'string', defaultValue: '' },
          ],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: CircleThumbnails,
  config: FlowCarouselHeaderBuilderConfig,
};

export default builderConfig;
