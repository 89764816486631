import { BuilderConfig } from '../../types/Builder';
import ImageList, { Props } from './image-list';

export const ImageListBuilderConfig = {
  name: 'Image List',
  inputs: [
    { name: 'title', type: 'string' },
    { name: 'description', type: 'string' },
    {
      name: 'imageList',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'imageUrl', type: 'file' },
        { name: 'title', type: 'string' },
        { name: 'description', type: 'richText' },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ImageList,
  config: ImageListBuilderConfig,
};

export default builderConfig;
