import { withChildren } from '@builder.io/react';
import { BACKGROUND_COLORS } from '../../shared/colors';
import { BuilderConfig } from '../../types/Builder';
import SectionBackground, { Props } from './section-background';

const SectionBackgroundWarpChildren = withChildren(SectionBackground);

export const SectionBackgroundBuilderConfig = {
  name: 'Section Background',
  inputs: [
    {
      name: 'categoryName',
      type: 'string',
      require: true,
      defaultValue: '',
    },
    {
      name: 'number',
      type: 'string',
    },
    {
      name: 'title',
      type: 'string',
      require: true,
    },
    {
      name: 'description',
      type: 'richText',
      defaultValue:''
    },
    {
      name: 'backgroundColor',
      type: 'string',
      defaultValue: BACKGROUND_COLORS.MOSS_20,
      enum: Object.values(BACKGROUND_COLORS),
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: SectionBackgroundWarpChildren,
  config: SectionBackgroundBuilderConfig,
};

export default builderConfig;
