import React from 'react';
import { BACKGROUND_COLOR } from '../../shared/colors';
import BackgroundFullWidth from '../background-full-width/background-full-width';
import VideoPLayer from '../video-player/video-player';
import styles from './video-player-warp.module.scss';
export interface Props {
  videoUrl: string;
  backgroundColor: BACKGROUND_COLOR;
}

const VideoPlayerWarp = (props: Props) => {
  const { videoUrl, backgroundColor } = props;

  const onClick = () => {
    return;
  };
  return (
    <div className={styles.videoPlayerWrap}>
      <VideoPLayer
        className={styles.videoPlayer}
        src={videoUrl}
        onClick={onClick}
        controls={true}
      />
      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default VideoPlayerWarp;
