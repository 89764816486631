import { BuilderConfig } from '../../types/Builder';
import ArticleCarousel, { Props } from './article-carousel';

export const ArticleCarouselBuilderConfig = {
  name: 'Article Carousel',
  inputs: [
    {
      name: 'images',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'imageUrl', type: 'file', require: true },
        { name: 'imageHref', type: 'string' },
        {
          name: 'tag',
          type: 'object',
          subFields: [
            { name: 'name', type: 'string' },
            { name: 'url', type: 'string' },
          ],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleCarousel,
  config: ArticleCarouselBuilderConfig,
};

export default builderConfig;
