import React, { FC } from 'react';

import styles from './carousel-header.module.scss';

export type CarouselHeaderType = {
  category?: string;
  number?: string;
  url?: string;
};

const CarouselHeader: FC<CarouselHeaderType> = ({
  category,
  number,
  url,
}) => {
  const renderCategory = () => {
    if (!category) return <div />;
    if (url) {
      return (
        <a href={url} className={styles.category}>
          {category}
        </a>
      );
    }
    return <div className={styles.category}>{category}</div>;
  };

  return (
    <div className={styles.wrapper}>
      {renderCategory()}
      {number ? <div className={styles.number}>{number}</div> : <div />}
    </div>
  );
};

export default CarouselHeader;
