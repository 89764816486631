import React from 'react'
import { Tag } from '../../types/Tag'
import SingleImage from '../single-image/single-image'
import styles from './single-image-warp.module.scss'
export type SingleImageWrapProps = {
  imageUrl: string
  tag: Tag
  imageHref?: string
}

const SingleImageWrap = (props: SingleImageWrapProps) => {
  const { imageUrl, tag, imageHref } = props

  return (
    <div className={styles.singleImageWrap}>
      <SingleImage
        imageUrl={imageUrl}
        tag={tag}
        imageHref={imageHref}
        tagPosition="bottom"
        ratio={[1096, 520]}
      />
    </div>
  )
}

export default SingleImageWrap
