import dynamic from 'next/dynamic';
import { SuperpowersCarouselType } from '.';
import { BuilderConfig } from '../../../types/Builder';
const LazySuperpowersCarousel = dynamic(() =>
  import('./index').then((module) => module as any)
);
export const SuperpowersCarouselBuilderConfig = {
  name: 'Superpowers Carousel',
  inputs: [
    {
      name: 'items',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
        },
        {
          name: 'image',
          type: 'file',
          required: true,
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<SuperpowersCarouselType> = {
  component: LazySuperpowersCarousel as any,
  config: SuperpowersCarouselBuilderConfig,
};

export default builderConfig;
