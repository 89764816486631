import { BuilderConfig } from '../../types/Builder';
import ArticleOrderedList, { Props } from './article-ordered-list';

export const OrderedListBuilderConfig = {
  name: 'Ordered List',
  inputs: [
    {
      name: 'orderedList',
      type: 'list',
      require: true,
      
      subFields: [
        { name: 'content', type: 'string', require: true },
        {
          name: 'nestedList',
          type: 'list',
          subFields: [{ name: 'contentChild', type: 'string', require: true }],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleOrderedList,
  config: OrderedListBuilderConfig,
};

export default builderConfig;
