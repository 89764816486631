import React from 'react';
import styles from './divider.module.scss';
export interface Props {
  height?: number;
}

const Divider = (props: Props) => {
  const { height } = props;
  const paddingHeight = height ? height / 2 : null;
  return (
    <div
      className={styles.divider}
      style={{ padding: `${paddingHeight}px 0` }}
    ></div>
  );
};

export default Divider;
