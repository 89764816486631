import classNames from 'classnames';
import React from 'react';
import { BACKGROUND_COLOR } from '../../shared/colors';
import { Tag } from '../../types/Tag';
import Button from '../Button';
import CategoryTag from '../category-tag/category-tag';
import Icons, { IconType } from '../Icons';
import styles from './single-image.module.scss';

export type SingleImageProps = {
  imageUrl: string;
  tag?: Tag;
  imageHref?: string;
  ratio?: number[];
  tagPosition?: 'top' | 'bottom';
  colorless?: boolean;
  className?: string;
  borderRadius?: number;
  isHover?: boolean;
  tagSize?: 'large' | 'normal';
  shape?: 'circle' | 'square';
  hoverBorderColor?: null | BACKGROUND_COLOR;
};

const SingleImage = (props: SingleImageProps) => {
  const {
    imageUrl,
    tag,
    imageHref,
    ratio = [],
    tagPosition = 'bottom',
    colorless = false,
    className,
    borderRadius,
    isHover = true,
    tagSize = 'large',
    shape = 'square',
    hoverBorderColor = null,
  } = props;

  const [ratioWidth, ratioHeight] = ratio;
  let imageBoxStyle = {};
  if (ratioWidth && ratioHeight) {
    const boxPaddingTop = (ratioHeight / ratioWidth) * 100;
    imageBoxStyle = { paddingTop: `${boxPaddingTop}%` };
  }
  const classNameSingleImage = classNames(
    styles.singleImage,
    className,
    colorless && styles.blindColor,
    isHover && styles.canHover,
    hoverBorderColor && styles[hoverBorderColor.split('-')[0]]
  );

  const shapeCircle = shape === 'circle' ? 1000 : borderRadius;

  const borderRadiusImage = { borderRadius: `${shapeCircle}px` };
  const classNameTag = classNames(`tag-${tagPosition}`);
  return (
    <>
      {imageHref && (
        <a
          href={imageHref}
          target="_self"
          rel="noopener"
          className={styles.imageLink}
        />
      )}
      <div className={classNameSingleImage} style={borderRadiusImage}>
        <div className={styles['image-box']} style={imageBoxStyle}>
          <div
            className={styles['image-main']}
            style={{ backgroundImage: `url('${imageUrl}')` }}
          ></div>
        </div>
        {tag && (
          <div className={styles.tags}>
            <CategoryTag tag={tag} className={classNameTag} size={tagSize} />
          </div>
        )}
        {shape === 'circle' && (
          <Button
            size="large"
            type="olive"
            icon={<Icons type={IconType.Play} />}
            className={styles.buttonPlay}
          />
        )}
      </div>
    </>
  );
};

export default SingleImage;
