import React from 'react';
import styles from './article-ordered-list.module.scss';
export interface Props {
  orderedList: OrderedListItem[];
}
interface OrderedListItem {
  content: string;
  nestedList?: ChildContent[];
}
interface ChildContent {
  contentChild: string;
}
const ArticleOrderedList = (props: Props) => {
  const { orderedList } = props;
  const renderChildContent = (content: ChildContent[]) => {
    if (!content) {
      return null;
    }
    return (
      <ul className={styles.orderedListChild}>
        {content.map((child: ChildContent) => (
          <li
            key={`child_content_key_${child.contentChild}`}
            className={styles.orderedListChildItem}
          >
            {child.contentChild}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <ol className={styles.articleOrderedList}>
      {(orderedList || []).map((item: OrderedListItem) => {
        return (
          <li key={item.content} className={styles.orderedListItem}>
            {item.content}
            {item?.nestedList && renderChildContent(item?.nestedList)}
          </li>
        );
      })}
    </ol>
  );
};

export default ArticleOrderedList;
