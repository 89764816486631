import React from 'react';
import { SwiperOptions } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SingleImage, { SingleImageProps } from '../single-image/single-image';
import styles from './article-hero-image.module.scss';
export interface Props {
  leadImages: SingleImageProps[];
}

const ArticleHeroImage = (props: Props) => {
  const { leadImages } = props;
  const swiperOption: SwiperOptions = {
    spaceBetween: 20,
    centeredSlides: true,
    slidesPerView: 'auto',
  };

  const renderSlide = (slide: SingleImageProps, index: number) => {
    return (
      <SwiperSlide key={`slider_image_key_${slide.imageUrl}+${index}`}>
        <SingleImage
          tag={slide?.tag?.name ? slide.tag : undefined}
          tagPosition="bottom"
          imageUrl={slide.imageUrl}
          imageHref={slide.imageHref}
          ratio={[1096, 494]}
        />
      </SwiperSlide>
    );
  };
  return (
    <div className={styles.articleHeroImage}>
      <div className={styles.articleSliderContainer}>
        <Swiper
          className={styles.articleSwiperContainer}
          dir="rtl"
          {...swiperOption}
        >
          {leadImages.map((slide: SingleImageProps, index: number) =>
            renderSlide(slide, index)
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default ArticleHeroImage;
