import classNames from 'classnames';
import React from 'react';
import { useWindowSize } from '../../../hook/useWindowSize';
import { BACKGROUND_COLOR } from '../../../shared/colors';
import BackgroundFullWidth from '../../background-full-width/background-full-width';
import Button from '../../Button';
import Icons, { IconType } from '../../Icons';
import SingleImage from '../../single-image/single-image';
import styles from './call-out-banner.module.scss';
export interface Props {
  backgroundColor: BACKGROUND_COLOR;
  title: string;
  listBenefit: BenefitItem[];
  imageUrl: string;
  linkDownLoad: string;
  paddingSize?: 'normal' | 'small';
}
interface BenefitItem {
  content: string;
}
const CallOutBanners = (props: Props) => {
  const {
    backgroundColor,
    title,
    listBenefit,
    imageUrl,
    linkDownLoad,
    paddingSize = 'normal',
  } = props;
  const { width = 0 } = useWindowSize();
  const ratioImage = width < 884 ? [224, 184] : [426, 307];
  const classNameCallOutBanner = classNames(
    styles.callOutBannerWrapper,
    styles[paddingSize]
  );
  const renderListBenefit = () => {
    if (!listBenefit) {
      return null;
    }
    return (
      <ul className={styles.benefit}>
        {listBenefit.map((item: BenefitItem) => (
          <li key={item.content}><div dangerouslySetInnerHTML={{__html: item.content}}/></li>
        ))}
      </ul>
    );
  };
  return (
    <div className={classNameCallOutBanner}>
      <div className={styles.callOutBanner}>
        <div className={styles.infoDetail}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: `${title}`,
            }}
          />
          {renderListBenefit()}
        </div>
        <div className={styles.qrCodeContainer}>
          <div className={styles.buttonRight}>
            <div className={styles.iconAppleStore}>
              <Icons type={IconType.AppleStore} />
            </div>

            <Button
              icon={<Icons type={IconType.ArrowRight} />}
              type="black"
              size="default"
              className={styles.iconArrowRight}
            ></Button>
          </div>
          <div className={styles.qrBoxImage}>
            <SingleImage
              ratio={ratioImage}
              imageUrl={imageUrl}
              imageHref={linkDownLoad}
              isHover={false}
            />
          </div>
        </div>
        <a href={linkDownLoad} target="_blank" rel="noreferrer"></a>
      </div>

      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default CallOutBanners;
