import { BuilderConfig } from '../../types/Builder';
import ArticleTextLargeWrap, { Props } from './article-text-large';

export const ArticleTextLargeBuilderConfig = {
  name: 'Article Text Large',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      require: true,
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleTextLargeWrap,
  config: ArticleTextLargeBuilderConfig,
};

export default builderConfig;
