import { BuilderConfig } from '../../types/Builder';
import SingleImageWrap, { SingleImageWrapProps } from './single-image-warp';

export const ArticleMetaBuilderConfig = {
  name: 'Single Image',
  inputs: [
    {
      name: 'imageUrl',
      type: 'file',
      require: true,
    },
    {
      name: 'imageHref',
      type: 'string',
    },
    {
      name: 'tag',
      type: 'object',
      subFields: [
        { name: 'name', type: 'string' },
        {
          name: 'url',
          type: 'string',
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<SingleImageWrapProps> = {
  component: SingleImageWrap,
  config: ArticleMetaBuilderConfig,
};

export default builderConfig;
