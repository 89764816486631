import { createShadow } from '../utils/create-shadow'

export const onSetTranslate = (swiper) => {
  const { width: swiperWidth, height: swiperHeight, slides, slidesSizesGrid } = swiper
  const params = swiper.params.coverflowEffect
  const isHorizontal = swiper.isHorizontal()
  const transform = swiper.translate
  const center = isHorizontal ? -transform + swiperWidth / 2 : -transform + swiperHeight / 2
  const rotate = isHorizontal ? params.rotate : -params.rotate
  const translate = params.depth
  // Each slide offset from center
  for (let i = 0, length = slides.length; i < length; i += 1) {
    const $slideEl = slides.eq(i)
    const slideSize = slidesSizesGrid[i]
    const slideOffset = $slideEl[0].swiperSlideOffset
    const slideProgress = $slideEl[0].progress
    const progress = Math.min(Math.max(slideProgress, -1), 1)
    const offsetMultiplier =
      ((center - slideOffset  - slideSize / 2) / slideSize) * params.modifier
    let rotateY = isHorizontal ? rotate * offsetMultiplier : 0
    let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier
    // var rotateZ = 0
    let translateZ = -translate * Math.abs(progress);

    let stretch = params.stretch
    // Allow percentage to make a relative stretch for responsive sliders
    if (typeof stretch === 'string' && stretch.indexOf('%') !== -1) {
      stretch = (parseFloat(params.stretch) / 100) * slideSize
    }
    let translateY = isHorizontal ? 0 : offsetMultiplier
    let translateX = isHorizontal ? offsetMultiplier : 0
    let scale = 1 - (1 - params.scale) * Math.abs(offsetMultiplier)

    if (isHorizontal) {
      const leftCenterOffset = center - slideSize
      const rightCenterOffset = center + slideSize / 2
      if (leftCenterOffset > slideOffset) {
        translateX -= params?.stretch || 0
      } else if (rightCenterOffset < slideOffset) {
        translateX += params?.stretch || 0
      }
    }

    // Fix for ultra small values
    if (Math.abs(translateX) < 0.001) translateX = 0
    if (Math.abs(translateY) < 0.001) translateY = 0
    if (Math.abs(translateZ) < 0.001) translateZ = 0
    if (Math.abs(rotateY) < 0.001) rotateY = 0
    if (Math.abs(rotateX) < 0.001) rotateX = 0
    if (Math.abs(scale) < 0.001) scale = 0

    const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(${scale})`
    let $targetEl = $slideEl
    if (params.transformEl) {
      $targetEl = $slideEl.find(params.transformEl).css({'backface-visibility': 'hidden', '-webkit-backface-visibility': 'hidden'})
    }
    $targetEl.transform(slideTransform)
    $slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1

    if (params.slideShadows) {
      // Set shadows
      let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top')
      let $shadowAfterEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom')
      if ($shadowBeforeEl.length === 0) {
        $shadowBeforeEl = createShadow(params, $slideEl, isHorizontal ? 'left' : 'top')
      }
      if ($shadowAfterEl.length === 0) {
        $shadowAfterEl = createShadow(params, $slideEl, isHorizontal ? 'right' : 'bottom')
      }
      if ($shadowBeforeEl.length)
        $shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0
      if ($shadowAfterEl.length)
        $shadowAfterEl[0].style.opacity = -offsetMultiplier > 0 ? -offsetMultiplier : 0
    }
  }
}