import { BuilderConfig } from '../../types/Builder';
import ArticleHeroImage, { Props } from './article-hero-image';

export const ArticleHeroImageBuilderConfig = {
  name: 'Article Hero Image',
  inputs: [
    {
      name: 'leadImages',
      type: 'list',
      defaultValue: [],
      subFields: [
        { name: 'imageUrl', type: 'file', require: true },
        { name: 'imageHref', type: 'string' },
        {
          name: 'tag',
          type: 'object',
          subFields: [
            { name: 'name', type: 'string' },
            { name: 'url', type: 'string' },
          ],
        },
      ],
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: ArticleHeroImage,
  config: ArticleHeroImageBuilderConfig,
};

export default builderConfig;
