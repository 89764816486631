import { withChildren } from '@builder.io/react';
import dynamic from 'next/dynamic';
import { BACKGROUND_COLORS } from '../../../shared/colors';
import { TYPES_LEAD_IMAGE } from '../../../shared/constants';
import { BuilderConfig } from '../../../types/Builder';
import { Props } from './hero-home-page';
const LazyHeroHomePage = dynamic(() =>
  import('./hero-home-page').then((mod) => mod as any)
);
const HeroMoneyPageWidthChildren = withChildren(LazyHeroHomePage);
export const MoneyHeroBuilderConfig = {
  name: 'Money Site Hero',
  inputs: [
    {
      name: 'title',
      type: 'string',
      required: true,
    },

    {
      name: 'leadImages',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'imageUrl',
          type: 'file',
          defaultValue: '',
          allowedFileTypes: ['png', 'jpeg'],
        },
        {
          name: 'imageHref',
          type: 'string',
          defaultValue: '',
        },
        {
          name: 'colorless',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'type',
          type: 'string',
          defaultValue: TYPES_LEAD_IMAGE.IMAGE,
          enum: Object.values(TYPES_LEAD_IMAGE),
        },
        {
          name: 'videoUrl',
          type: 'file',
          defaultValue: '',
          allowedFileTypes: ['mp4'],
        },
      ],
    },
    {
      name: 'backgroundColor',
      type: 'string',
      enum: Object.values(BACKGROUND_COLORS),
    },
    {
      name: 'introDescription',
      type: 'richText',
      required: false,
    },
    {
      name: 'borderRadius',
      type: 'number',
      required: false,
    },
  ],
};

const builderConfig: BuilderConfig<Props> = {
  component: HeroMoneyPageWidthChildren as any,
  config: MoneyHeroBuilderConfig,
};

export default builderConfig;
