import React from 'react'
import styles from './article-quote.module.scss'
export interface Props {
  quote: string
  authorName?: string
}

const ArticleQuote = (props: Props) => {
  const { quote, authorName } = props
  return (
    <div className={styles.articleQuote}>
      <q className={styles.quote}>{quote}</q>
      {authorName && <p className={styles.authorName}>{authorName}</p>}
    </div>
  )
}

export default ArticleQuote
