import classNames from 'classnames';
import React, { useContext } from 'react';
import { PageColorCtx } from '../../../helpers/shareContext';
import { BACKGROUND_COLOR, BACKGROUND_COLORS } from '../../../shared/colors';
import BackgroundFullWidth from '../../background-full-width/background-full-width';
import ImageHero from '../../image-hero/image-hero';
import styles from './hero-money-flow-page.module.scss';

export interface Props {
  leadImages: LeadImage[];
  backgroundColor?: BACKGROUND_COLOR | string;
  borderRadius?: string;
  title?: string;
  introTitle?: string;
  introDescription?: string;
  children?: JSX.Element;
}
export type LeadImage = {
  imageUrl?: string;
  imageHref?: string;
  colorless?: boolean;
  type: 'image' | 'video';
  videoUrl?: string;
};
const HeroMoneyFlowPage = (props: Props) => {
  const {
    leadImages,
    backgroundColor = BACKGROUND_COLORS.MOSS_40,
    borderRadius,
    title,
    introTitle,
    introDescription,
    children,
  } = props;

  const {  pageColorCtx } = useContext(PageColorCtx);
  const getSrc = (index: number) =>
    leadImages[index].type === 'image'
      ? leadImages[index].imageUrl
      : leadImages[index].videoUrl;
  const classNameIntroDescription = classNames(
    styles.introDescription,
    children && styles.heroHadChildren
  );

  return (
    <div className={styles.heroMoneyFlowPageWrapper}>
      <h1 className={styles.heroTitle}>{title}</h1>
      <div className={styles.heroMoneyHeroContainer}>
        <ImageHero
          colorless={leadImages[0]?.colorless}
          type={leadImages[0]?.type}
          borderRadius={borderRadius}
          hoverColor={pageColorCtx as BACKGROUND_COLOR}
          imageHref={leadImages[0]?.imageHref}
          src={getSrc(0) || ''}
          ratio={[311, 305]}
          className={'leadImage imageFirst'}
        />
        <ImageHero
          colorless={leadImages[1]?.colorless}
          type={leadImages[1]?.type}
          borderRadius={borderRadius}
          hoverColor={pageColorCtx as BACKGROUND_COLOR}
          imageHref={leadImages[1]?.imageHref}
          src={getSrc(1) || ''}
          ratio={[311, 305]}
          className={'leadImageCircle imageSecond'}
        />
        <ImageHero
          colorless={leadImages[2]?.colorless}
          type={leadImages[2]?.type}
          borderRadius={borderRadius}
          hoverColor={pageColorCtx as BACKGROUND_COLOR}
          imageHref={leadImages[2]?.imageHref}
          src={getSrc(2) || ''}
          ratio={[327, 646]}
          backgroundColor={backgroundColor}
          className={'leadImage imageThirst'}
        />

        <ImageHero
          type={leadImages[3]?.type}
          borderRadius={borderRadius}
          hoverColor={pageColorCtx as BACKGROUND_COLOR}
          imageHref={leadImages[3]?.imageHref}
          src={getSrc(3) || ''}
          ratio={[578, 646]}
          className={'leadImage imageFourth'}
          colorless={leadImages[3]?.colorless}
        />
      </div>

      <div className={styles.intro}>
        <div
          className={styles.introTitle}
          dangerouslySetInnerHTML={{ __html: `${introTitle}` }}
        ></div>
        <div
          className={classNameIntroDescription}
          dangerouslySetInnerHTML={{ __html: `${introDescription}` }}
        />
      </div>
      {children && <div className={styles.videoWrap}>{children}</div>}
      <BackgroundFullWidth backgroundColor={backgroundColor} />
    </div>
  );
};

export default HeroMoneyFlowPage;
